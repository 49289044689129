import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { Col, Row } from 'react-bootstrap';
import { FormTypes, sectionList } from '../../constants/queryFragments';
import loadable from '@loadable/component';
import {
  Body,
  HeaderH2,
  HeaderH3,
  MainContainer,
  ParaText,
  HeadingH4,
  UnorderedList,
  LinkStyle,
  ListItem,
  HorizontalBreak,
} from '../../styles';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../utils';

const ColumnListWith3Items = ({ sectionData }) => {
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    setLoaded(true);
  }, []);
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <HeaderH2 textAlign="left">{children}</HeaderH2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <HeaderH3 textAlign="left">{children}</HeaderH3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => <HeadingH4>{children}</HeadingH4>,
      [BLOCKS.PARAGRAPH]: (node, children) => <ParaText>{children}</ParaText>,
      [BLOCKS.UL_LIST]: (node, children) => (
        <UnorderedList>{children}</UnorderedList>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
      [INLINES.HYPERLINK]: (node, children) => (
        <LinkStyle href={isExternalUrlHref(node?.data?.uri)? node?.data?.uri :addTrailingSlash(process.env.GATSBY_DOMAIN_URL + node?.data?.uri)}
        target = {
          isExternalUrl(node?.data?.uri)? '_blank' : '_self'
        }>{children}</LinkStyle>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return !loaded ? (
    <MainContainer>
      <Body>
        {sectionData?.header?.trim() && (
          <HeaderH2 $textAlign>{sectionData?.header}</HeaderH2>
        )}
        {sectionData?.subtext?.trim() && (
          <HeaderH3>{sectionData?.subtext}</HeaderH3>
        )}
        {sectionData?.listDetails &&
          documentToReactComponents(
            JSON.parse(sectionData?.listDetails?.raw),
            optionsMainStyle
          )}
        {sectionData && (
          <>
            <Row>
              {sectionData?.list &&
                sectionData?.list?.map((section, index) => {
                  if (section?.type && sectionList?.includes(section?.type) &&
                  !FormTypes?.includes(section?.type)) {
                    // const sectionData = sectionData.list[section.type];
                    const SectionComponent = loadable(() =>
                      import(
                        `../../components/${section?.type}/${section?.type}`
                      )
                    );
                    return (
                      <Col xs={12} md={6} lg={3} key={index}>
                        <SectionComponent key={index} sectionData={section} />
                      </Col>
                    );
                  }
                  return null;
                })}
            </Row>
            <HorizontalBreak />
          </>
        )}
      </Body>
    </MainContainer>
  ) : (
    <MainContainer>
    <Body>
      {sectionData?.header?.trim() && (
        <HeaderH2 $textAlign>{sectionData?.header}</HeaderH2>
      )}
      {sectionData?.subtext?.trim() && (
        <HeaderH3>{sectionData?.subtext}</HeaderH3>
      )}
      {sectionData?.listDetails &&
        documentToReactComponents(
          JSON.parse(sectionData?.listDetails?.raw),
          optionsMainStyle
        )}
      {sectionData && (
        <>
          <Row>
            {sectionData?.list &&
              sectionData?.list?.map((section, index) => {
                if (section?.type && sectionList?.includes(section?.type)) {
                  // const sectionData = sectionData.list[section.type];
                  const SectionComponent = loadable(() =>
                    import(
                      `../../components/${section?.type}/${section?.type}`
                    )
                  );
                  return (
                    <Col xs={12} md={6} lg={3} key={index}>
                      <SectionComponent key={index} sectionData={section} />
                    </Col>
                  );
                }
                return null;
              })}
          </Row>
          <HorizontalBreak />
        </>
      )}
    </Body>
  </MainContainer>
  );
};
export default ColumnListWith3Items;
